import React, { useEffect } from "react";
import Spinner from "../../custom_components/Spinner/Spinner";
import { withAuthentication } from "@/hoc/withAuthentication";
import { warningAlert } from "@/utility/helpers";
import { withRouter } from "react-router";
// import { getActiveShop } from "@/constants/utility";

const Processing = ({ match, httpClient, history, user }) => {
  const id = match.params.id;
  const params = new URLSearchParams(history.location.search);
  const subscription_id = params.get("subscription_id") || "";
  const ba_token = params.get("ba_token");
  
  useEffect(() => {
    if (id == "success") {
      httpClient
        .post(`/checkout/successful/${subscription_id}?ba_token=${ba_token}&shop=${user.selectedShop}`)
        .then((res) => {
          console.log(res);
          warningAlert({ message: "Payment successful.", status: "success" });
          setTimeout(() => {
            window.location.href = "/pricing";
          }, 2000);
        })
        .catch((err) => {
          console.log(err);
          // warningAlert({ message: "Payment failed.", status: "danger" });
          setTimeout(() => {
            window.location.href = "/pricing";
          }, 2000);
        });
    }

    if (id == "cancel") {
      warningAlert({ message: "Payment Cancelled.", status: "danger" });
      history.push("/pricing");
    }
  }, [id]);

  return (
    <Spinner>
      <div className="uk-light">{id || "Redirecting to payment gateway."}</div>
      <div className="uk-light uk-text-bold">Do not close this window</div>
    </Spinner>
  );
};

export default withRouter(withAuthentication(Processing));
